exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ask-complete-js": () => import("./../../../src/pages/ask/complete.js" /* webpackChunkName: "component---src-pages-ask-complete-js" */),
  "component---src-pages-ask-index-js": () => import("./../../../src/pages/ask/index.js" /* webpackChunkName: "component---src-pages-ask-index-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-goods-js": () => import("./../../../src/pages/goods.js" /* webpackChunkName: "component---src-pages-goods-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-category-goods-list-js": () => import("./../../../src/templates/category-goods-list.js" /* webpackChunkName: "component---src-templates-category-goods-list-js" */),
  "component---src-templates-goods-detail-js": () => import("./../../../src/templates/goods-detail.js" /* webpackChunkName: "component---src-templates-goods-detail-js" */)
}

